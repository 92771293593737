import React from "react"

import GetInTouchForm from "./getintouch_form"
// import backgrounImage from "../static/images/background/01.jpg"
import ApricusLogo from "../static/images/logo.svg"
// import iFastLogo from "../static/images/iFast--logo.png"

export default function GetInTouch() {
  return (
    <section class="statistics-section">
      <div class="outer-container outer-container-sm">
        <div class="image-column">
          <div class="image-column--inner image-column--inner-sm">
            <div className="">
              <img
                src={ApricusLogo}
                alt="Apricus Wealth Advisors"
                class="iFast--logo ml-2 sm:mml-0 img-thumbnail mb-4"
              />
              {/* <img
                src={iFastLogo}
                alt="iFAST Corporation Ltd"
                class="iFast--logo ml-2 img-thumbnail mb-4"
              /> */}
            </div>
            {/* <p>
              Kunal Bhatia is a Person Associated with Investment Advice (PAA) with iFAST Global
              Markets (www.ifastgm.co.in), the investment advisory division of iFAST Financial India
              Pvt Ltd., a corporate SEBI Registered Investment Adviser. Apricus Wealth is a unit of
              iFAST Global Markets
            </p> */}

            <div class="row mt-3">
              <div class="col-12 col-md-6 col-sm-12">
                <p>
                  <strong>Apricus Wealth Investment Managers LLP</strong>
                  <br />
                  Type of Registration: Non-Individual
                  <br />
                  Registration number: INA000017657 (Validity: Perpetual)
                </p>

                <p>
                  <strong>Complete address with telephone no:</strong>
                  <br />
                  Apricus Wealth Investment Managers LLP
                  <br />
                  Flat no. 1105, 11th Floor, 1106 Skipper House, 22, Fire Brigade Lane
                  <br />
                  Barakhamba, Connaught Place
                  <br />
                  New Delhi - 110001
                  <br />
                  +91-98110 04140
                </p>

                <p>
                  <strong>Disclaimer & Standard Warnings</strong>
                  <br />
                  Registration granted by SEBI, membership of BASL (in case of IAs) and
                  certification from NISM in no way guarantee the performance of the intermediary or
                  provide any assurance of returns to investors.
                  <br />
                  <br />
                  Investments in securities market are subject to market risks. Read all the related
                  documents carefully before investing.
                </p>
                <p>
                  <strong>
                    <a
                      class="text-white"
                      href="https://scores.sebi.gov.in/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ borderBottom: "1px solid #fff" }}
                    >
                      Access Scores Portal
                    </a>
                  </strong>{" "}
                  |{" "}
                  <strong>
                    <a
                      class="text-white"
                      href="https://smartodr.in/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ borderBottom: "1px solid #fff" }}
                    >
                      Access ODR Portal
                    </a>
                  </strong>
                </p>
                <p>
                  <strong></strong>
                </p>
              </div>
              <div class="col-12 col-md-6 col-sm-12">
                <p>
                  <strong>Contact details of the Principal Officer:</strong>
                  <br />
                  Name: Kunal Bhatia
                  <br />
                  Contact number - +91-98110 04140
                  <br />
                  Email id -{" "}
                  <a
                    href="mailto:kunal.bhatia@apricuswealth.in"
                    class="text-white"
                    style={{ borderBottom: "1px solid #fff" }}
                  >
                    kunal.bhatia@apricuswealth.in
                  </a>
                </p>

                <p>
                  <strong>Contact details of the Compliance Officer:</strong>
                  <br />
                  Name: Kunal Bhatia
                  <br />
                  Contact number - +91-98110 04140
                  <br />
                  Email id -{" "}
                  <a
                    href="mailto: kunal.bhatia@apricuswealth.in"
                    class="text-white"
                    style={{ borderBottom: "1px solid #fff" }}
                  >
                    kunal.bhatia@apricuswealth.in
                  </a>
                </p>

                <p>
                  <strong>Corresponding SEBI regional/local office address:</strong>
                  <br />
                  Securities and Exchange Board of India
                  <br />
                  The Regional Director
                  <br />
                  5th Floor, Bank of Baroda Building
                  <br />
                  16, Sansad Marg, New Delhi - 110001
                  <br />
                  Delhi
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="content-column">
          <div class="content-column--inner content-column--inner-sm">
            <div class="sec-title light">
              <h4 class="h1 h1-sm">Get in touch</h4>
              <p class="text">
                If you have any questions, would like to know more about us, or if you would like to
                book an appointment, please enter your details and we’ll be in touch to discuss your
                needs.
              </p>
            </div>
            <GetInTouchForm />
          </div>
        </div>
      </div>
    </section>
  )
}
